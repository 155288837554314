import { useContext, useEffect, useState } from "react";
import CustomInput from "../components/Input";
import { getUserData, updateData } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../utils/showToast";
import { loginUserByAPI } from "../components/api/authentication";

import bg from "../assets/bg.png";
import intro from "../assets/intro.png";
import banner from "../assets/banner.png";
import qua_1 from "../assets/qua_1.png";
import qua_2 from "../assets/qua_2.png";
import tick_1 from "../assets/tick_1.png";
import tick_2 from "../assets/tick_2.png";
import DataContext from "../context/DataContext";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const dataUser = useSelector(getUserData);
  const { isNeedLogout, setIsNeedLogout } = useContext(DataContext)!;
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  useEffect(() => {
    if (dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  const handleLogin = async () => {
    try {
      console.log("handleLogin", formData);
      if (formData.name === "") {
        showToast({ type: "error", message: "Vui lòng nhập tên" });

        return;
      }
      if (formData.phone === "") {
        showToast({ type: "error", message: "Vui lòng nhập số điện thoại" });

        return;
      }
      setIsLoading(true);
      const result = await loginUserByAPI(formData);
      setIsLoading(false);

      if (result) {
        if (result.status == 200) {
          if (result.data.status) {
            setIsNeedLogout(false);
            dispatch(updateData(result.data.data));
          } else {
            showToast({ type: "error", message: result.data.message });
          }
        } else {
          showToast({ type: "error", message: result.message });
        }
      } else {
        showToast({ type: "error", message: "Có lỗi xảy ra vui lòng thử lại" });
      }
    } catch (error: any) {
      setIsLoading(false);
      showToast({ type: "error", message: "Có lỗi xảy ra vui lòng thử lại" });
    }
  };

  return (
    <>
      {step == 1 ? (
        <>
          <div className="w-full min-h-[700px] max-h-screen h-screen relative bg-gradient-to-b from-[#010101] to-[#4a0b15] flex items-center justify-center">
            <div className="flex overflow-hidden flex-col relative justify-center mx-auto w-full text-base font-bold text-black max-w-[480px]">
              <img
                loading="lazy"
                srcSet={intro}
                className="object-contain z-0 w-full aspect-[0.56]"
              />
            </div>
            <div
              onClick={() => {
                setStep(2);
              }}
              className="font-tiktok-display font-bold text-xl min-w-[306px] absolute left-1/2 transform -translate-x-1/2 bottom-10 gap-2.5 text-center p-4 bg-[#00e7e7] rounded-lg border-4 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#000000] hover:text-[#FFFFFF]"
            >
              ĐĂNG KÍ THAM GIA SỰ KIỆN
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="min-h-screen w-full flex flex-col">
            <div className="flex gap-2.5 items-start mx-auto min-h-screen w-full">
              <div
                className="flex relative flex-col items-center w-[427px] min-w-[240px] mx-auto bg-cover bg-no-repeat bg-bottom"
                style={{ backgroundImage: `url(${bg})` }}
              >
                {/* Banner luôn canh top */}
                <div className="w-full">
                  <img
                    loading="lazy"
                    srcSet={banner}
                    className="object-contain z-0 max-w-full w-[431px]"
                  />
                  <div className="flex absolute right-0 z-0 gap-10 justify-between items-center max-w-full top-[243px] w-[427px]">
                    <img
                      loading="lazy"
                      srcSet={tick_1}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.79] w-[33px]"
                    />
                    <img
                      loading="lazy"
                      srcSet={tick_2}
                      className="object-contain shrink-0 self-stretch my-auto w-14 aspect-[1.3]"
                    />
                  </div>
                </div>

                {/* Container cho phần còn lại với flex-grow để lấp đầy không gian */}
                <div className="flex-1 flex items-center justify-center w-full">
                  {/* Content */}
                  <div className="flex z-0 flex-col w-full px-4">
                    {/* Title */}
                    <div className="z-0 self-center text-xl font-tiktok-text font-medium text-center text-white w-[326px]">
                      Tham gia Check-in
                      <br />
                      sự kiện LIVE VUI VÔ ĐỐI cùng TikTok Shop có cơ hội nhận
                      ngay
                    </div>

                    {/* Prizes */}
                    <div className="flex z-0 justify-between w-full font-tiktok-text text-base mt-5">
                      <div className="flex flex-col w-1/2">
                        <img
                          loading="lazy"
                          srcSet={qua_1}
                          className="object-contain self-center max-w-full aspect-[0.83] w-[120px]"
                        />
                        <div className="flex flex-col mt-4 w-full">
                          <div className="self-center font-medium text-white">
                            Giải 1:
                          </div>
                          <div className="mt-2 text-center font-regular text-xs text-cyan-400">
                            iPhone 16 256GB
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2">
                        <img
                          loading="lazy"
                          srcSet={qua_2}
                          className="object-contain self-center max-w-full aspect-[0.97] w-[138px]"
                        />
                        <div className="flex flex-col mt-4 w-full">
                          <div className="self-center font-medium text-white">
                            Giải 2:
                          </div>
                          <div className="mt-2 text-center font-regular text-xs text-cyan-400">
                            Apple Watch S10 GPS 42mm
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Form */}
                    <div className="flex z-0 flex-col px-14 w-full text-base font-bold text-center text-white mt-5">
                      <CustomInput
                        type="text"
                        placeholder="HỌ & TÊN"
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            name: e.target.value,
                          })
                        }
                      />
                      <CustomInput
                        type="text"
                        placeholder="SỐ ĐIỆN THOẠI"
                        value={formData.phone}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </div>

                    {/* Button */}
                    {isLoading ? (
                      <div className="gap-2.5 self-center mt-5 py-4 px-8 text-base font-tiktok-text font-bold text-white bg-black rounded-lg border-2 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)]">
                        ĐANG XỬ LÝ
                      </div>
                    ) : (
                      <div
                        onClick={handleLogin}
                        className="gap-2.5 self-center mt-5 py-4 px-8 text-base font-tiktok-text font-bold text-white bg-black rounded-lg border-2 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#00E7E7] hover:text-[#000000]"
                      >
                        CHECK IN
                      </div>
                    )}

                    {/* Terms & Conditions */}
                    <div className="flex z-0 mt-5 flex-col pb-5 w-full text-white">
                      <div className="text-sm font-tiktok-text font-bold">
                        T&C:
                      </div>
                      <div className="mt-2 font-tiktok-text font-light text-xs">
                        TikTok Shop thu nhập Thông tin & SĐT chỉ sử dụng cho mục
                        đích nhất trao giải đặc biệt cho khách tham gia tại sự
                        kiện từ 5PM – 10PM
                      </div>
                      <div className="mt-2 font-tiktok-text font-light text-[0.65rem] pl-1">
                        * Tôi đồng ý các điều khoản và điểu kiện của chương
                        trình
                        <br />* Tôi chấp nhận chính sách quyền riêng tư
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
