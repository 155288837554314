import { ValuePropsGetUser } from "../../utils/interface.global";
import { URL_API } from "../constants/api";
import Api from "./api";

export const loginUserByAPI = async (props: ValuePropsGetUser) => {
  const reqUrl = URL_API.REGISTER;

  const res = await Api.post(reqUrl, props);

  return res;
};

export const loadDataUserByAPI = async (props: any) => {
  const reqUrl = URL_API.GET_USER;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const resendOTPByAPI = async (props: any) => {
  const reqUrl = URL_API.RESEND_OTP;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const activeUserByAPI = async (props: any) => {
  const reqUrl = URL_API.ACTIVE_USER;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const postTestDriveByAPI = async (props: any) => {
  const reqUrl = URL_API.TEST_DRIVE;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res;
};

export const getLuckydrawByAPI = async (props: any) => {
  const reqUrl = URL_API.LUCKYDRAW;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const checkQuestionByAPI = async (props: any) => {
  const reqUrl = URL_API.CHECK_QUESTION;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};

export const saveAnswerByAPI = async (props: any) => {
  const reqUrl = URL_API.SAVE_ANSWER;

  const res = await Api.post(reqUrl, props);
  //console.log("res", res.data);
  return res.data;
};
