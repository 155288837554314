import React, { useState } from "react";

// Define props interface
interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

// Add props type to component
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      ></div>

      {/* Dialog */}
      <div className="relative bg-gradient-to-b from-[#010101] to-[#4a0b15] rounded-lg p-6 w-[90%] max-w-md border-2 border-red-500 shadow-[0px_0px_10px_rgba(234,64,53,0.5)]">
        <div className="flex flex-col items-center">
          {/* Title */}
          <h3 className="font-tiktok-display font-bold text-xl text-white mb-4">
            Xác nhận
          </h3>

          {/* Content */}
          <p className="font-tiktok-text text-white text-center mb-6">
            Bạn có chắc chắn không?
          </p>

          {/* Buttons */}
          <div className="flex gap-4 w-full">
            <button
              onClick={onClose}
              className="flex-1 px-4 py-2 bg-black text-white font-bold rounded-full border border-red-500 shadow-[0px_0px_4px_rgba(234,64,53,1)] transition-colors duration-300 hover:bg-[#00E7E7] hover:text-black"
            >
              KHÔNG
            </button>
            <button
              onClick={onConfirm}
              className="flex-1 px-4 py-2 bg-black text-white font-bold rounded-full border border-red-500 shadow-[0px_0px_4px_rgba(234,64,53,1)] transition-colors duration-300 hover:bg-[#00E7E7] hover:text-black"
            >
              CÓ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
