import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import ScrollToTop from "../components/ScrollTopTop";

export default function Root() {
  return (
    <>
      <Outlet />
    </>
  );
}
