export enum URL_API {
  REGISTER = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/user_register",
  ACTIVE_USER = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/active_register",
  RESEND_OTP = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/resend-otp",
  GET_USER = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/get_data_user",
  TEST_DRIVE = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/test-drive",
  CHECK_QUESTION = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/check-question",
  SAVE_ANSWER = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/save-answer",
  LUCKYDRAW = "https://api-tiktokshop1111livevuivodoi.codev.vn/api/luckydraw",
}
