import { createContext } from "react";

const DataContext = createContext<{
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  isNeedLogout: boolean;
  setIsNeedLogout: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export default DataContext;
