import { ChangeEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput = ({
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="gap-2.5 self-stretch px-4 py-2 mt-5 w-full bg-black border border-[#2d0d10] border-solid rounded-[1000px] shadow-[0px_0px_4px_rgba(234,64,53,1)] placeholder-white focus:outline-none focus:ring-0"
      placeholder={placeholder}
    />
  );
};

export default CustomInput;
