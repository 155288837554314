import React, { useEffect, useRef, useState } from "react";
import bg from "../assets/bg.png";
import bg_2 from "../assets/bg_2.png";
import banner from "../assets/banner.png";
import tick_1 from "../assets/tick_1.png";
import tick_2 from "../assets/tick_2.png";
import tick_3 from "../assets/tick_3.png";
import bg_1 from "../assets/bg_1.png";
import voucher_1 from "../assets/voucher_1.png";
import voucher_2 from "../assets/voucher_2.png";

import huong_dan_1 from "../assets/huong_dan_1.png";
import huong_dan_2 from "../assets/huong_dan_2.png";

import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout, updateData } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import {
  activeUserByAPI,
  loadDataUserByAPI,
  postTestDriveByAPI,
  resendOTPByAPI,
} from "../components/api/authentication";
import { showToast } from "../utils/showToast";
import ConfirmDialog from "../components/ConfirmDialog";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);

  const [formData, setFormData] = useState({
    name: "",
    id: 0,
    token: "",
    phone: "",
    da_mua_tiktok_shop: "",
  });

  useEffect(() => {
    if (!dataUser) {
      navigate("/Login");
    }

    setFormData({
      ...formData,
      id: dataUser.id,
      token: dataUser.token,
      phone: dataUser.phone,
      da_mua_tiktok_shop: dataUser.da_mua_tiktok_shop,
    });

    setStep(dataUser.da_mua_tiktok_shop == "" ? 1 : 4);
  }, [dataUser]);

  const onClickAnswer = (answer: number) => {
    setSelectedAnswer(answer);
    setIsDialogOpen(true);
  };

  const handleConfirm = () => {
    submitData(selectedAnswer);
    setIsDialogOpen(false);
  };

  const submitData = async (answer: number) => {
    setFormData({
      ...formData,
      da_mua_tiktok_shop: answer.toString(),
    });
    setStep(2);
  };
  const onClickNext = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    if (step == 4) {
      onSubmitData();
    }
  }, [step]);

  const onSubmitData = async () => {
    try {
      setIsLoading(true);
      const result = await activeUserByAPI(formData);
      setIsLoading(false);
      if (result) {
        if (result.status == 200) {
          if (result.data.status) {
            //showToast({ type: "success", message: result.data.message });
          } else {
            showToast({ type: "error", message: result.data.message });
          }
        } else {
          showToast({ type: "error", message: result.message });
        }
      } else {
        showToast({ type: "error", message: "Có lỗi xảy ra vui lòng thử lại" });
      }
    } catch (error) {
      setIsLoading(false);
      showToast({ type: "error", message: "Có lỗi xảy ra vui lòng thử lại" });
    }
  };

  return (
    <>
      {step == 1 && (
        <>
          <div className="min-h-screen w-full flex flex-col">
            <div className="flex overflow-hidden gap-2.5 items-start mx-auto min-h-screen w-full">
              <div
                className="flex relative flex-col items-center w-[427px] min-w-[240px] mx-auto h-screen  bg-cover bg-no-repeat bg-bottom"
                style={{ backgroundImage: `url(${bg})` }}
              >
                {/* Banner luôn canh top */}
                <div className="w-full">
                  <img
                    loading="lazy"
                    srcSet={banner}
                    className="object-contain z-0 max-w-full w-[431px]"
                  />
                  <div className="flex absolute right-0 z-0 gap-10 justify-between items-center max-w-full top-[243px] w-[427px]">
                    <img
                      loading="lazy"
                      srcSet={tick_1}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.79] w-[33px]"
                    />
                    <img
                      loading="lazy"
                      srcSet={tick_2}
                      className="object-contain shrink-0 self-stretch my-auto w-14 aspect-[1.3]"
                    />
                  </div>
                </div>

                {/* Container cho phần còn lại với flex-grow để lấp đầy không gian */}
                <div className="flex-1 flex items-center justify-center w-full">
                  {/* Div bg_1 */}
                  <div
                    className="flex relative z-0 flex-col w-full justify-center font-tiktok-text font-medium text-center text-white bg-contain bg-no-repeat bg-top mt-[-40px] min-h-[500px]"
                    style={{ backgroundImage: `url(${bg_1})` }}
                  >
                    <div className="z-0 self-center text-xl w-[326px] mt-8">
                      Bạn đã từng
                      <br />
                      trải nghiệm mua sắm
                      <br />
                      trên <span className="font-bold">TikTok Shop</span> chưa?
                    </div>
                    <div className="flex z-0 flex-col px-20 mt-8 w-full text-base font-bold whitespace-nowrap">
                      <div
                        onClick={() => {
                          onClickAnswer(1);
                        }}
                        className="gap-2.5 self-stretch px-4 py-2 w-full bg-black border border-red-500 border-solid rounded-[1000px] shadow-[0px_0px_4px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#00E7E7] hover:text-[#000000]"
                      >
                        CÓ
                      </div>
                      <div
                        onClick={() => {
                          onClickAnswer(2);
                        }}
                        className="gap-2.5 self-stretch px-4 py-2 mt-5 w-full bg-black border border-red-500 border-solid rounded-[1000px] shadow-[0px_0px_4px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#00E7E7] hover:text-[#000000]"
                      >
                        CHƯA
                      </div>
                    </div>
                    <img
                      loading="lazy"
                      srcSet={tick_3}
                      className="object-contain absolute right-0 z-0 aspect-[0.59] bottom-[38px] h-[125px] w-[74px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onConfirm={handleConfirm}
          />
        </>
      )}
      {step == 2 && (
        <>
          <div className="min-h-screen w-full flex flex-col">
            <div className="flex overflow-hidden gap-2.5 items-start mx-auto min-h-screen w-full">
              <div
                className="flex relative flex-col items-center w-[427px] min-w-[240px] mx-auto h-screen bg-cover bg-no-repeat bg-bottom"
                style={{ backgroundImage: `url(${bg})` }}
              >
                {/* Banner luôn canh top */}
                <div className="w-full">
                  <img
                    loading="lazy"
                    srcSet={banner}
                    className="object-contain z-0 max-w-full w-[431px]"
                  />
                  <div className="flex absolute right-0 z-0 gap-10 justify-between items-center max-w-full top-[243px] w-[427px]">
                    <img
                      loading="lazy"
                      srcSet={tick_1}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.79] w-[33px]"
                    />
                    <img
                      loading="lazy"
                      srcSet={tick_2}
                      className="object-contain shrink-0 self-stretch my-auto w-14 aspect-[1.3]"
                    />
                  </div>
                </div>

                {/* Container cho phần còn lại với flex-grow để lấp đầy không gian */}
                <div className="flex-1 flex items-center justify-center w-full">
                  {/* Content */}
                  <div className="flex z-0 flex-col p-5 w-full text-center text-white font-tiktok-text">
                    {/* Title */}
                    <div className="self-center text-2xl font-regular">
                      {formData.da_mua_tiktok_shop == "1" ? (
                        <>
                          Voucher chốt đơn
                          <br />
                          vui quên lối về
                          <br />
                          tại{" "}
                          <span className="font-bold">TikTok Shop 11.11</span>
                        </>
                      ) : (
                        <>
                          Voucher bạn mới,
                          <br />
                          chốt đơn giá hời
                          <br />
                          tại <span className="font-bold">TikTok Shop</span>
                        </>
                      )}
                    </div>

                    {/* Voucher Image & Code */}
                    <div className="flex flex-col justify-center mt-8 w-full text-base font-bold">
                      <img
                        loading="lazy"
                        srcSet={
                          formData.da_mua_tiktok_shop == "1"
                            ? voucher_1
                            : voucher_2
                        }
                        className="object-contain self-center max-w-full aspect-[1.78] w-[250px]"
                      />
                      <div className="flex flex-col px-20 mt-4 w-full">
                        <div className="gap-2.5 self-stretch px-4 py-2 w-full bg-black border border-red-500 border-solid rounded-[1000px] shadow-[0px_0px_4px_rgba(234,64,53,1)]">
                          {formData.da_mua_tiktok_shop == "1"
                            ? "Mã: livevuivodoi"
                            : "Mã: salevuivodoi"}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={onClickNext}
                      className="gap-2.5 self-center mt-5 py-4 px-8 text-base font-tiktok-text font-bold text-white bg-black rounded-lg border-2 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#00E7E7] hover:text-[#000000]"
                    >
                      TIẾP TỤC
                    </div>
                    {/* Footer Text */}
                    <div className="px-5 w-full text-center text-white font-tiktok-text font-light text-xs mt-4">
                      *Vui lòng lưu ngay mã voucher <br />
                      và sử dụng trong ngày{" "}
                      <span className="font-bold">10.11</span>, vì số lượng có
                      hạn
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step == 3 && (
        <>
          <div className="min-h-screen w-full flex flex-col">
            <div className="flex overflow-hidden gap-2.5 items-start mx-auto min-h-screen w-full">
              <div
                className="flex relative flex-col items-center w-[427px] min-w-[240px] mx-auto h-screen bg-cover bg-no-repeat bg-bottom"
                style={{ backgroundImage: `url(${bg})` }}
              >
                {/* Banner luôn canh top */}
                <div className="w-full">
                  <img
                    loading="lazy"
                    srcSet={banner}
                    className="object-contain z-0 max-w-full w-[431px]"
                  />
                  <div className="flex absolute right-0 z-0 gap-10 justify-between items-center max-w-full top-[243px] w-[427px]">
                    <img
                      loading="lazy"
                      srcSet={tick_1}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.79] w-[33px]"
                    />
                    <img
                      loading="lazy"
                      srcSet={tick_2}
                      className="object-contain shrink-0 self-stretch my-auto w-14 aspect-[1.3]"
                    />
                  </div>
                </div>

                {/* Container cho phần còn lại với flex-grow để lấp đầy không gian */}
                <div className="flex-1 flex items-center justify-center w-full">
                  {/* Content */}
                  <div className="flex z-0 flex-col p-5 w-full text-center text-white font-tiktok-text">
                    <div className="flex flex-col justify-center w-full text-base font-bold">
                      <img
                        loading="lazy"
                        srcSet={
                          formData.da_mua_tiktok_shop == "1"
                            ? huong_dan_1
                            : huong_dan_2
                        }
                        className="max-w-full w-full"
                      />
                    </div>
                    <div
                      onClick={onClickNext}
                      className="gap-2.5 self-center mt-5 py-4 px-8 text-base font-tiktok-text font-bold text-white bg-black rounded-lg border-2 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)] transition-colors duration-300 cursor-pointer hover:bg-[#00E7E7] hover:text-[#000000]"
                    >
                      TIẾP TỤC
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {step == 4 && (
        <>
          <div className="min-h-screen w-full flex flex-col">
            <div className="flex overflow-hidden gap-2.5 items-start mx-auto min-h-screen w-full">
              <div
                className="flex relative flex-col items-center w-[427px] min-w-[240px] mx-auto h-screen bg-cover bg-no-repeat bg-bottom"
                style={{ backgroundImage: `url(${bg})` }}
              >
                {/* Container cho phần còn lại với flex-grow để lấp đầy không gian */}
                <div
                  className="flex-1 flex items-end justify-center w-full bg-cover bg-no-repeat bg-bottom"
                  style={{ backgroundImage: `url(${bg_2})` }}
                >
                  {/* Content */}
                  <div className="flex z-0 flex-col p-5 w-full text-center text-white font-tiktok-text">
                    <div className="flex flex-col justify-center w-full">
                      <div className="m-auto gap-2.5 self-stretch p-4 w-[80%] font-tiktok-text font-bold text-lg text-white bg-black rounded-lg border-4 border-red-500 border-solid shadow-[0px_0px_10px_rgba(234,64,53,1)]">
                        CHÚC MỪNG BẠN
                        <br />
                        ĐÃ ĐĂNG KÝ THÀNH CÔNG
                        <br />
                        THAM GIA SỰ KIỆN
                      </div>
                      <div className="mt-10 text-cyan-400 font-tiktok-text font-regular text-base">
                        <span className="">Cảm ơn bạn</span>
                        <br />
                        <span className="">đã săn sale</span>{" "}
                        <b>11.11 LIVE VUI VÔ ĐỐI</b>
                        <br />
                        <span className="">cùng TikTok Shop</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
