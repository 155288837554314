import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import ProtectedLogin from "./auth/ProtectedLogin";
import Login from "./pages/Login";
import Logout from "./pages/logout";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout } from "./redux/slices/userSlices";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { element: <Navigate to="/Login" />, index: true },
      {
        path: "/Login",
        element: <ProtectedLogin props={<Login />} type={""} key={""} />,
      },
    ],
  },
  {
    path: "/game/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "logout", element: <Logout /> },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isNeedLogout, setIsNeedLogout] = useState(true);
  const dataUser = useSelector(getUserData);

  useEffect(() => {
    if (dataUser && isNeedLogout) {
      dispatch(logout());
    }
  }, []);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        isNeedLogout,
        setIsNeedLogout,
      }}
    >
      <div className="font-MMC_OFFICE w-full bg-black">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
